<template>
  <div>
    <Layout ref="layout">
        <ModalShare/>
        <div class="head-enterprise-page px-4 mb-3">
            <div class="d-flex flex-column">
                <span class="title-file-shared-v3 my-2">People with access in File {{ file.file_name }}</span>
                <button class="btn-inv-participant-share main-btn-api mb-3" @click="invParticipant()">Invite</button>
            </div>
        </div>
        <!-- <span>{{item}}</span> -->
        <div class="list-enterprise-member px-4">
            <table class="table-enterprise">
                <thead>
                    <th>Email</th>
                    <!-- <th>Last active</th> -->
                    <th>Role</th>
                    <th></th>
                </thead>
                <tbody v-if="item == null">
                    <tr>
                        <td>
                            <div class="d-flex">
                                <div class="avatar-xs">
                                    <span class="avatar-title rounded-circle">{{email.charAt(0).toUpperCase()}}</span>
                                </div>
                                <span class="text-participant-user-shared">
                                    {{ email }}
                                </span>
                            </div>
                        </td>
                        <td><span class="text-share-file-participant-v3">Owner</span></td>
                        <td>
                            <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-share-file-users">
                                <template #button-content>
                                    <div class="d-flex align-items-center">
                                        <img src="@/assets/images/icon/btn-more.svg" alt="">
                                    </div>
                                </template>
                                <b-dropdown-item @click="copyToClipboard(email)">
                                    <div class="d-flex justify-content-start ml-2">
                                        <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                                        <span class="text-share-file-participant-v3 mx-3">Copy email</span>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td>
                            <div class="d-flex">
                                <div class="avatar-xs">
                                    <span class="avatar-title rounded-circle">{{email.charAt(0).toUpperCase()}}</span>
                                </div>
                                <span class="text-participant-user-shared">
                                    {{ email }}
                                </span>
                            </div>
                        </td>
                        <td><span class="text-share-file-participant-v3">Owner</span></td>
                        <td>
                            <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-share-file-users">
                                <template #button-content>
                                    <div class="d-flex align-items-center">
                                        <img src="@/assets/images/icon/btn-more.svg" alt="">
                                    </div>
                                </template>
                                <b-dropdown-item @click="copyToClipboard(email)">
                                    <div class="d-flex justify-content-start ml-2">
                                        <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                                        <span class="text-share-file-participant-v3 mx-3">Copy email</span>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                    <tr v-for="(participant,idx) in item.users_id" :key="idx">
                        <td>
                            <div class="d-flex">
                                <div class="avatar-xs">
                                    <span class="avatar-title rounded-circle">{{participant.email.charAt(0).toUpperCase()}}</span>
                                </div>
                                <span class="text-participant-user-shared">
                                    {{ participant.email }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <b-dropdown id="dropdown-share-file-participant">
                                <template #button-content>
                                <div class="d-flex align-items-center">
                                    <span class="text-share-file-participant-v3 mr-2">{{ participant.access_type == "View" ? 'Viewer' : 'Editor' }}</span>
                                    <img src="@/assets/images/icon/chevDown.svg" alt="">
                                </div>
                                </template>
                                <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="updateAccess(participant)">
                                    <div class="d-flex justify-content-start ml-2">
                                        <img v-if="access.id == participant.access_type" src="@/assets/images/icon/Checklist.svg" alt="">
                                        <span v-else class="mx-2"></span>
                                        <span class="text-share-file-participant-v3 mx-3">{{ access.label }}</span>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                        <td>
                            <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-share-file-users">
                                <template #button-content>
                                    <div class="d-flex align-items-center">
                                        <img src="@/assets/images/icon/btn-more.svg" alt="">
                                    </div>
                                </template>
                                <b-dropdown-item @click="copyToClipboard(participant.email)">
                                    <div class="d-flex justify-content-start ml-2">
                                        <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                                        <span class="text-share-file-participant-v3 mx-3">Copy email</span>
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item @click="removeParticipant(participant)">
                                    <div class="d-flex justify-content-start ml-2">
                                        <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt="">
                                        <span class="text-share-file-participant-v3 mx-3">Remove</span>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <pre>
            item : {{ item }}
        </pre> -->
    </Layout>
  </div>
</template>

<script>
import Layout from '../../router/layouts/main.vue'
import ModalShare from './modalShare.vue'

export default {
    data() {
        return {
            accessTypes : [
            {
                id : "View",
                label : "Viewer",
                selected : true
            },
            {
                id : "Write",
                label : "Editor",
                selected : false
            }
        ]
        }
    },
    components: {
        Layout,
        ModalShare
    },
    computed: {
        file(){
            return this.$store.getters["storage/item"]
        },
        item(){
            var data = this.$store.getters["storage/item"]
            return data.users_share[0]
        },
        email() {
            return this.$store.getters['user/email']
        },
    },
    mounted() {
        this.$store.dispatch('user/getLimit')
        this.$store.dispatch("storage/fileretrieve",{_id : this.$route.query.id, directory: this.$route.query.d})
    },
    methods: {
        copyToClipboard(email){
            navigator.clipboard.writeText(email)
        },
        removeParticipant(participant){
            var params = {
                id : this.item._id,
                email : participant.email,
                file_id : this.item.files_id,
                directory : this.item.directory
            }
            this.$store.dispatch("share/remove_user", params)
            .then(() => {
                this.$store.dispatch("storage/fileretrieve",{_id : this.$route.query.id, directory: this.$route.query.d})
                .then(()=>{
                    if(this.item == undefined || this.item == null){
                        this.$router.push('/').catch(()=>{})
                    }
                    this.$toasted.show('This participant has been removed by you.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                })
            })
        },
        invParticipant(){
            this.$store.commit("storage/SET_ITEM", this.file)
            this.$root.$emit('bv::show::modal', 'modal-share-file-v3')
        },
        updateAccess(participant){
            var params = {
                id : this.item._id,
                email : participant.email,
                access_type : participant.access_type == "View" ? "Write" : "View",
                file_id : this.item.files_id,
                directory : this.item.directory
            }
            this.$store.dispatch("share/edit_access", params)
            .then((_) => {
                    this.$toasted.show('User role has been changed.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            })
        }
    }
}
</script>

<style lang="scss">
#dropdown-share-file-participant button{
  background: none;
  border: none;
  padding: 0;
}
#dropdown-share-file-participant ul{
  min-width: 130px;
  height: fit-content;
  &.show{
    top: 100% !important;
  }
  :hover{
    background: transparent !important;
  }
}
#dropdown-share-file-participant ul li{
  margin: 0;
  border: none !important;
  padding: 4px 8px;
}
#dropdown-share-file-participant ul li a{
  padding: 0;
}
#dropdown-share-file-participant button:focus{
  background: none;
}

.title-file-shared-v3{
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.04em;
    color: #262A2C;
}
.btn-inv-participant-share{
    color: #FFFFFF;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    font-weight: 400;
    border-radius: 4px;
    border: none;
    width: 70px;
}
.share-file-user-page{
    padding: 24px 0 0 24px !important;
}
.table-user-share-file-v3 thead{
    background: #F6F6FC;
    border-radius: 4px;
    height: 36px;
}
.table-user-share-file-v3 thead tr th{
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  color: #919394;
  letter-spacing: -1px;
  font-weight: 300;
  border-top: none !important;
  border-bottom: none !important;
}
.table-user-share-file-v3 tbody{
  height: 52px !important;
}
.table-user-share-file-v3 tbody tr td{
  padding-left: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: middle;
  border-bottom: 1px solid #E9EAEA !important;
}
.container-table-share-file-participant{
    max-height: 67vh;
    height: 67vh;
    overflow-x: hidden;
    overflow-y: scroll;
}
.text-share-file-participant-v3{
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
    color: #262A2C;
}
.text-participant-user-shared{
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: -1px;
    color: #262A2C;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 8px;
}

.dropdown-more-share-file-users:focus{
  background: none !important;
}
.dropdown-more-share-file-users button{
  background: none !important;
  border: none;
  padding: 0;
  &:hover{
    background: none;
  }
}
.dropdown-more-share-file-users button:focus{
  background: none !important;
}
.dropdown-more-share-file-users ul{
  &.active{
    background: none !important;
    background-color: none !important;
  }
  &.show{
    top: 130% !important;
  }
  :hover{
    background: transparent !important;
  }
}
.dropdown-more-share-file-users ul li{
  margin-left: 0 !important;
  border-bottom: none !important;
}
</style>